export default class Roles {

    static ROLES_COMUM = [
        {label: "Perfis",                       value: ["____PERF_M", "____PERF_C", "____PERF_E", "____PERF_D"]},		
		{label: "Produto",                      value: ["____PROD_M", null        , "____PROD_E", "____PROD_D"]},
		{label: "Equipamentos",                 value: ["____EQPT_M", "____EQPT_C", "____EQPT_E", "____EQPT_D"]},
		{label: "Gestores",                     value: ["____GEST_M", "____GEST_C", "____GEST_E", "____GEST_D"]},
		{label: "Regionais",                    value: ["____REGN_M", "____REGN_C", "____REGN_E", "____REGN_D"]},
		{label: "Vendedores",                   value: ["____VEND_M", "____VEND_C", "____VEND_E", "____VEND_D"]},
		{label: "Usuários",                     value: ["____USER_M", "____USER_C", "____USER_E", "____USER_D"]},
        {label: "Budgets",                      value: ["____BUDG_M", "____BUDG_C", "____BUDG_E", "____BUDG_D"]},
        {label: "Negociações",                  value: ["GER_NEGO_M", "GER_NEGO_C", "GER_NEGO_E", "GER_NEGO_D"]},
		{label: "Clientes",                     value: ["____CLIE_M", "____CLIE_C", "____CLIE_E", "____CLIE_D"]},
        {label: "Lista de Preço",               value: ["____LSTP_M", "____LSTP_C", "____LSTP_E", "____LSTP_D"]},
        {label: "Alçada",                       value: ["____ALCD_M", "____ALCD_C", "____ALCD_E", "____ALCD_D"]},
        {label: "Contas de E-mail",             value: ["____MAIL_M", "____MAIL_C", "____MAIL_E", "____MAIL_D"]}
    ];

    static ROLES_AAP = [		
        {label: "Metas",                        value: ["____META_M", "____META_C", "____META_E", "____META_D"]},
		{label: "Painel CAC",                   value: ["CAC_PCAC_M"]},
        {label: "Pedidos",                      value: ["CAC_PEDD_M", "CAC_PEDD_C", "CAC_PEDD_E", null ]},
        {label: "Inadimplência",                value: ["GER_AAP_INAD_M"]},
        {label: "Painel Gerencial",             value: ["GER_AAP_PGER_M"]},        
        {label: "Acompanhamento Diário",        value: ["PCP_ACPD_M"]},        
		{label: "Painel Volume",           value: ["RFI_PVOL_M"]},
		{label: "Painel Moeda",            value: ["RFI_PMOE_M"]},
		{label: "Pedidos",                      value: ["RFI_PEDD_M"]}

    ];
    
}
