import { Button } from "primereact/components/button/Button";
import React from "react";
import TZMCheckbox from "../../../components/common/TZMCheckbox";
import TZMTextField from "../../../components/common/TZMTextField";
import TZMDialog from "../../../components/dialog/TZMDialog";
import TZMPopup from "../../../components/dialog/TZMPopup";
import { ModalRealm } from "../../../components/modal/ModalRealm";
import TZMPanelFooter from "../../../components/panel/TZMPanelFooter";
import TZMTabPanel from "../../../components/panel/TZMTabPanel";
import TZMTabView from "../../../components/panel/TZMTabView";
import TZMColumn from "../../../components/table/TZMColumn";
import TZMTable from "../../../components/table/TZMTable";
import TZMShortcut from "../../../utilities/TZMShortcut";
import { PerfilService } from "../../servicos/PerfilService";
import Roles from "./Roles";

const empresa = process.env.REACT_APP_EMPRESA;

export default class EditarPerfil extends React.Component {

	constructor(props) {
		super();
		this.state = {
			tab: 0,
			visible: true,
			alterado: false,
			perfil: Object.assign({}, props.perfil),
			roles: []
		};		
		this.perfilService = new PerfilService();
	}

	carregarRoles = () => {
		let roles = [];		
		switch (empresa) {
			case 'COLACRIL':
				roles = Roles.ROLES_COMUM.concat(Roles.ROLES_AAP);
				break;
			case 'RFID':
				roles = Roles.ROLES_COMUM.concat(Roles.ROLES_AAP);
				break;									
			case 'RRE':	
				roles = Roles.ROLES_COMUM.concat(Roles.ROLES_RRE);				
				break;
			default:
				break;	
		}
		roles.sort((a, b) => a.label.localeCompare(b.label));			
		this.setState({roles});  
	}

	componentDidMount(){
		this.carregarRoles();
	}

	onClose = () => {
		if (this.props.onClose) {
			this.props.onClose();
		}
		this.setState({visible: false});
	}
 
	onSalvar = () => {
		const salvar = this.state.perfil;
		const messages = [];
		if (!salvar.descricao || !salvar.descricao.length) {
			messages.push("A descrição do perfil é obrigatória");
		}
		if (!salvar.roles || !salvar.roles.length) {
			messages.push("O perfil deve ter ao menos uma permissão atribuída");
		}
		if (messages.length) {
			ModalRealm.showDialog(<TZMPopup messages={messages} />);
		} else {
			this.perfilService.salvar(salvar).then((perfil) => {
				if (this.props.onModalClose) {
					this.props.onModalClose(perfil);
				}
				this.onClose();
			});
		}
	}

	handleChange = (event) => {
		const perfil = this.state.perfil;
		perfil[event.name] = event.value;
		this.setState({perfil});
	}	

	handleTabChange = (event) => {
		this.setState({tab: event.index});
	}
	roleDetails = [
		<TZMColumn style={{width: "*"}} header="Permissão" key={0} body={(r) => <span style={{cursor: "pointer", userSelect: "none"}} onClick={() => this.toggleAllH(r)}>{r.label}</span>} />,
		<TZMColumn style={{width: "5em", textAlign: "center"}} header={<span style={{cursor: "pointer", userSelect: "none"}} onClick={() => this.toggleAllV(0)}>Menu</span>} key={1} body={(r) => r.value[0] ? <TZMCheckbox checked={this.state.perfil.roles.includes(r.value[0])} name={r.value[0]} onChange={this.onCheck} /> : null} />,
		<TZMColumn style={{width: "5em", textAlign: "center"}} header={<span style={{cursor: "pointer", userSelect: "none"}} onClick={() => this.toggleAllV(1)}>Criar</span>} key={2} body={(r) => r.value[1] ? <TZMCheckbox checked={this.state.perfil.roles.includes(r.value[1])} name={r.value[1]} onChange={this.onCheck} /> : null} />,
		<TZMColumn style={{width: "5em", textAlign: "center"}} header={<span style={{cursor: "pointer", userSelect: "none"}} onClick={() => this.toggleAllV(2)}>Editar</span>} key={3} body={(r) => r.value[2] ? <TZMCheckbox checked={this.state.perfil.roles.includes(r.value[2])} name={r.value[2]} onChange={this.onCheck} /> : null} />,
		<TZMColumn style={{width: "5em", textAlign: "center"}} header={<span style={{cursor: "pointer", userSelect: "none"}} onClick={() => this.toggleAllV(3)}>Excluir</span>} key={4} body={(r) => r.value[3] ? <TZMCheckbox checked={this.state.perfil.roles.includes(r.value[3])} name={r.value[3]} onChange={this.onCheck} /> : null} />
	];

	onCheck = (event) => {
		const perfil = this.state.perfil;
		if (event.value) {
			perfil.roles.push(event.name);
		} else {
			perfil.roles = perfil.roles.filter(r => r !== event.name);
		}
		this.setState({perfil});
	}

	toggleAllH = (role) => {
		const perfil = this.state.perfil;
		if (perfil.roles.includes(role.value[0])) {
			role.value.filter(r => r).forEach(r => perfil.roles = perfil.roles.filter(s => s !== r));
		} else {
			role.value.filter(r => r).forEach(r => perfil.roles.push(r));
		}
		this.setState({perfil});
	}

	toggleAllV = (index) => {
		const perfil = this.state.perfil;
		if (perfil.roles.includes(this.state.roles[0].value[index])) {
			this.state.roles.forEach(r => perfil.roles = perfil.roles.filter(s => s !== r.value[index]));
		} else {
			this.state.roles.forEach(r => {
				if (r.value[index] && !perfil.roles.includes(r.value[index])) {
					perfil.roles.push(r.value[index]);
				}
			});
		}
		this.setState({perfil});
	}

	renderPaineis = () => {
				
		switch (empresa) {
			case 'COLACRIL':
				return (
					<div className="ui-g">
						<TZMTabView activeIndex={this.state.tab} onTabChange={this.handleTabChange}>
							<TZMTabPanel header={<span><span className="ui-tab-number" children="1" />Cadastros</span>}>
								<div className="ui-g">															
									<TZMTable col={6} value={this.state.roles.filter((v,i) =>  this.state.roles.length / 2 > i && v.value.some(f => f && f.startsWith('____')))} children={this.roleDetails} />
									<TZMTable col={6} value={this.state.roles.filter((v,i) =>  this.state.roles.length / 2 <= i && v.value.some(f => f && f.startsWith('____')))} children={this.roleDetails} />								
								</div>
							</TZMTabPanel>
							<TZMTabPanel header={<span><span className="ui-tab-number" children="2" />CAC</span>}>
								<div className="ui-g">							
									<TZMTable col={6} value={this.state.roles.filter((v,i) =>  v.value.some(f => f && f.startsWith('CAC_')))} children={this.roleDetails} />																
								</div>
							</TZMTabPanel>
							<TZMTabPanel header={<span><span className="ui-tab-number" children="3" />PCP</span>}>
								<div className="ui-g">							
									<TZMTable col={6} value={this.state.roles.filter((v,i) =>  v.value.some(f => f && f.startsWith('PCP_')))} children={this.roleDetails} />																
								</div>
							</TZMTabPanel>
							<TZMTabPanel header={<span><span className="ui-tab-number" children="4" />Gerencial</span>}>
								<div className="ui-g">							
									<TZMTable col={6} value={this.state.roles.filter((v,i) =>   v.value.some(f => f && f.startsWith('GER_')))} children={this.roleDetails} />																
								</div>
							</TZMTabPanel>
							<TZMTabPanel header={<span><span className="ui-tab-number" children="5" />RFID</span>}>
								<div className="ui-g">							
									<TZMTable col={6} value={this.state.roles.filter((v,i) =>   v.value.some(f => f && f.startsWith('RFI_')))} children={this.roleDetails} />							
									
								</div>
							</TZMTabPanel>
							<TZMTabPanel header={<span><span className="ui-tab-number" children="6" />Projetos</span>}>
								<div className="ui-g">							
									<TZMTable col={6} value={this.state.roles.filter((v,i) => v.value.some(f => f && f.startsWith('PRJ_')))} children={this.roleDetails} />																
								</div>
							</TZMTabPanel>
						</TZMTabView>						
					</div>
				);									
			case 'RRE':	
				return (
					<div className="ui-g">
						<TZMTabView activeIndex={this.state.tab} onTabChange={this.handleTabChange}>
							<TZMTabPanel header={<span><span className="ui-tab-number" children="1" />Cadastros</span>}>
								<div className="ui-g">							
									<TZMTable col={6} value={this.state.roles.filter((v,i) =>  this.state.roles.length / 2 > i && v.value.some(f => f && f.startsWith('____')))} children={this.roleDetails} />
									<TZMTable col={6} value={this.state.roles.filter((v,i) =>  this.state.roles.length / 2 <= i && v.value.some(f => f && f.startsWith('____')))} children={this.roleDetails} />
								</div>
							</TZMTabPanel>
							<TZMTabPanel header={<span><span className="ui-tab-number" children="2" />Comercial</span>}>
								<div className="ui-g">							
									<TZMTable col={6} value={this.state.roles.filter((v,i) =>   v.value.some(f => f && f.startsWith('CRC_')))} children={this.roleDetails} />																
								</div>
							</TZMTabPanel>						

							<TZMTabPanel header={<span><span className="ui-tab-number" children="3" />PCP</span>}>
								<div className="ui-g">							
									<TZMTable col={6} value={this.state.roles.filter((v,i) =>   v.value.some(f => f && f.startsWith('PCP_')))} children={this.roleDetails} />																
								</div>
							</TZMTabPanel>						
							<TZMTabPanel header={<span><span className="ui-tab-number" children="4" />Gerencial</span>}>
								<div className="ui-g">							
									<TZMTable col={6} value={this.state.roles.filter((v,i) => v.value.some(f => f && f.startsWith('GER_')))} children={this.roleDetails} />																
								</div>
							</TZMTabPanel>
							
						</TZMTabView>						
					</div>
				);	
				default:
					break;					
			}
		
	}
	
	render() {		
		return (
			<TZMDialog style={{width: "1000px"}} visible={this.state.visible} modal header="Perfil" {...this.props}>
				
				<div className="ui-g">
					<TZMTextField col={12} placeholder="Descrição" name="descricao" value={this.state.perfil.descricao} onChange={this.handleChange} />
				</div>
				{this.renderPaineis()}
				<TZMPanelFooter>
					<Button className="ui-button-success ui-tzm-button" label="Salvar" icon="fas fa-save" onClick={this.onSalvar} />
					<Button className="ui-button-secondary ui-tzm-button" label="Fechar" icon="fas fa-times" onClick={this.onClose} />
				</TZMPanelFooter>
				<TZMShortcut active={this.state.visible} onCtrlS={this.onSalvar} onEscape={this.onClose} />
			</TZMDialog>
		);
	}

}
